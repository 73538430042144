@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,600;0,700;0,800;1,400&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;
/* 
@layer base {
  * {
    @apply font-main;
  }
} */
